<template>
<v-dialog v-if="!!user" v-model="dialog" width="650">
  <v-card>
    <v-toolbar dense dark color="primary">
      <div class="text-subtitle-1">新增身份</div>
      <v-spacer></v-spacer>
      <v-icon @click="dialog=false">mdi-close</v-icon>
    </v-toolbar>
    <v-container class="pa-8" fluid>
      <div class="d-flex">
        <div class="red--text">*</div>
        <div class="text-caption grey--text">选择人员</div>
        <v-spacer></v-spacer>
      </div>
      <!-- user -->
      <div v-if="isNew">
        <v-menu :nudge-width="-300" offset-y>
          <template v-slot:activator="{attrs, on}">
            <v-btn v-bind="attrs" v-on="on" block dense outlined color="grey">
              <div v-if="!user.id">请选择</div>
              <v-chip @click:close="user.id=null" label close v-else>{{ user.id | username }}</v-chip>
              <v-spacer></v-spacer>
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <user-picker @pick="setUser"></user-picker>
        </v-menu>
      </div>
      <div class="d-flex align-center" v-else>
        <div class="blue darken-4 mr-2" style="width:5px;height:20px;"></div>
        <div class="text-subtitle-1">{{ user.id | username }}</div>
      </div>
      <!-- label -->
      <div class="d-flex mt-6">
        <div class="text-caption grey--text">选择角色：</div>
        <v-spacer></v-spacer>
      </div>
      <!-- tabs -->
      <div class="mt-3">
        <v-tabs v-model="tab" icons-and-text height="36">
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">
            <div class="text-caption">系统管理员</div>
          </v-tab>
          <!-- <v-tab href="#tab-2">
            <div class="text-caption">模块管理员</div>
          </v-tab> -->
        </v-tabs>
        <v-tabs-items class="pa-3" v-model="tab">
          <v-tab-item id="tab-1">
            <!-- user role -->
            <v-radio-group v-model="user.user_role">
              <v-radio value="admin">
                <template v-slot:label>
                  <div class="d-flex">
                    <div class="text-caption black--text" style="width:100px;">超级管理员</div>
                    <div class="text-caption grey--text">默认是企业第一个注册用户，拥有下列所有权限和模块管理员的所有权限</div>
                  </div>
                </template>
              </v-radio>
              <v-radio value="editor">
                <template v-slot:label>
                  <div class="d-flex">
                    <div class="text-caption black--text" style="width:100px;">后台管理员</div>
                    <div class="text-caption grey--text">管理企业信息、组织架构、员工、管理员授权等</div>
                  </div>
                </template>
              </v-radio>
              <v-radio value="creator">
                <template v-slot:label>
                  <div class="d-flex">
                    <div class="text-caption black--text" style="width:100px;">助理</div>
                    <div class="text-caption grey--text">拥有模块管理员的所有权限</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-tab-item>
          <v-tab-item id="tab-2">
            <!-- user permissions -->
            <v-checkbox class="mt-0" value="0" v-model="user.user_permission">
              <template v-slot:label>
                <div class="d-flex">
                  <div class="text-caption black--text" style="width:150px;">【项目】管理员</div>
                  <div class="text-caption grey--text">可对企业内部全部项目进行管理</div>
                </div>                
              </template>
            </v-checkbox>
            <v-checkbox class="mt-n4" value="1" v-model="user.user_permission">
              <template v-slot:label>
                <div class="d-flex">
                  <div class="text-caption black--text" style="width:150px;">【考核】管理员</div>
                  <div class="text-caption grey--text">可对企业所有人发起考核并进行管理</div>
                </div>                
              </template>
            </v-checkbox>
            <v-checkbox class="mt-n4" value="2" v-model="user.user_permission">
              <template v-slot:label>
                <div class="d-flex">
                  <div class="text-caption black--text" style="width:150px;">【目标】管理员</div>
                  <div class="text-caption grey--text">可对企业内部全部目标进行管理</div>
                </div>                
              </template>
            </v-checkbox>
            <v-checkbox class="mt-n4" value="3" v-model="user.user_permission">
              <template v-slot:label>
                <div class="d-flex">
                  <div class="text-caption black--text" style="width:150px;">【动态\文件】管理员</div>
                  <div class="text-caption grey--text">可对企业所有发布的分享及文件进行管理</div>
                </div>                
              </template>
            </v-checkbox>
            <v-checkbox class="mt-n4" value="4" v-model="user.user_permission">
              <template v-slot:label>
                <div class="d-flex">
                  <div class="text-caption black--text" style="width:150px;">【总结】管理员</div>
                  <div class="text-caption grey--text">可在【日常工作报表】查看、导出企业所有人的总结数据</div>
                </div>                
              </template>
            </v-checkbox>
            <v-checkbox class="mt-n4" value="5" v-model="user.user_permission">
              <template v-slot:label>
                <div class="d-flex">
                  <div class="text-caption black--text" style="width:150px;">【签到】管理员</div>
                  <div class="text-caption grey--text">可在【日常工作报表】查看、导出企业所有人的签到数据</div>
                </div>                
              </template>
            </v-checkbox>
          </v-tab-item>
        </v-tabs-items>
        <!-- buttons -->
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn @click="dialog=false" class="mx-1" small>取消</v-btn>
          <v-btn @click="agree" class="mx-1" color="primary" small>确认</v-btn>
        </div>
      </div>
    </v-container>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: 'AdminUserDlg',
  components: {
    UserPicker: () => import("@/components/common/UserPicker.vue")
  },
  data: () => ({
    dialog: false,
    user: null,
    isNew: null,
    tab: null,
  }),
  methods: {
    open(user) {
      this.user = user;
      this.user.user_permission = !user.user_permission ? [] : user.user_permission.split(',').filter(p => p != '');
      this.isNew = !!user.id ? false : true;
      this.tab = null;
      this.dialog = true;
    },
    setUser(e) {
      this.user.id = e.user.id;
    },
    agree() {
      this.$emit('agree', this.user);
      this.dialog = false;
    }
  }
}
</script>
